// import ring1 from './rings/ring1.png';
import ring2 from "./rings/ring2.png";
import ring3 from "./rings/ring3.png";
// import ring4 from './rings/ring4.png';
// import ring5 from './rings/ring5.png';
// import ring6 from './rings/ring6.png';
// import ring7 from './rings/ring7.png';
// import ring8 from './rings/ring8.png';
// import ring9 from './rings/ring9.png';
import ring10 from "./rings/ring10.png";
// import ring11 from './rings/ring11.png';

import vars from "variables";

const css = `
  body[path="${vars.pages.index}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring2}),
                      url(${ring3});
    background-position: top 23.75% left,
                         top 41.7% left;
    background-size: 70%,
                     40%;
  }

  body[path="${vars.pages.games}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring10});
    background-position: top 1960px left -40px;
    background-size: auto;
  }
`;

export default css;
