import styled from "styled-components";

import logoBlack from "assets/Common/Logo/black.svg";

import vars from "variables";

const sections = vars.navigation
  .map(
    ({ identifier }) =>
      `[data-section="${identifier}"] & [data-menu="${identifier}"]`
  )
  .join(",\n");

const Root = styled.div`
  ${vars.desktop.mediaQuery} {
    &.scaled {
      min-width: ${vars.scaleWidth}px;
    }

    [data-menu] {
      display: none;
    }

    [data-section] & a.index-logo {
      background-image: url(${logoBlack});
    }

    ${sections} {
      display: flex;
    }
  }

  ${vars.mobile.mediaQuery} {
    ${sections} {
      transform: none;
    }
  }
`;

export { Root };
