import ring1 from "./rings/ring1.png";
import ring2 from "./rings/ring2.png";
import ring3 from "./rings/ring3.png";
import ring4 from "./rings/ring4.png";
import ring5 from "./rings/ring5.png";
import ring6 from "./rings/ring6.png";
import ring7 from "./rings/ring7.png";
import ring8 from "./rings/ring8.png";
import ring9 from "./rings/ring9.png";
import ring10 from "./rings/ring10.png";
import ring11 from "./rings/ring11.png";

import vars from "variables";

const css = `
  body[path="${vars.pages.index}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring2}),
                      url(${ring3}),
                      url(${ring4}),
                      url(${ring5}),
                      url(${ring6}),
                      url(${ring7}),
                      url(${ring8}),
                      url(${ring9});
    background-position: top 1700px left,
                         top 3200px left,
                         top 3650px right,
                         top 4300px left,
                         top 4400px left,
                         top 4500px right,
                         top 6500px left,
                         top 6550px right;
    background-size: auto,
                     auto,
                     auto,
                     auto,
                     auto,
                     auto,
                     auto,
                     auto;
  }

  body[path="${vars.pages.aboutUs}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring1}),
                      url(${ring3}),
                      url(${ring4}),
                      url(${ring10});
    background-position: top 100px right -150px,
                         top 700px left,
                         top 1400px right,
                         top 4450px left;
    background-size: 850px 1100px,
                     auto,
                     auto,
                     auto;
  }

  body[path^="${vars.pages.claim}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring6}),
                      url(${ring11});
    background-position: top 1250px left,
                         top 1450px right -10px;
    background-size: auto 400px,
                     auto 400px;
  }

  body[path="${vars.pages.exchanges}"] #___gatsby,
  body[path="${vars.pages.fundraising}"] #___gatsby,
  body[path="${vars.pages.hodlRanks}"] #___gatsby,
  body[path="${vars.pages.whitepaper}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring3}),
                      url(${ring4}),
                      url(${ring10});
    background-position: top 150px left,
                         top 300px right,
                         top 1100px left;
    background-size: auto,
                     auto,
                     auto;
  }

  body[path="${vars.pages.faqs}"] #___gatsby,
  body[path^="${vars.pages.resourceLibrary}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring6}),
                      url(${ring4}),
                      url(${ring5});
    background-position: top 50px left,
                         top 600px right,
                         top 1000px left;
    background-size: auto 600px,
                     auto 500px,
                     auto;
  }

  body[path="${vars.pages.hodlForward}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring1}),
                      url(${ring3}),
                      url(${ring4}),
                      url(${ring10}),
                      url(${ring4});
    background-position: top 120px right -300px,
                         top 800px left,
                         top 1200px right,
                         top 2150px left,
                         top 2700px right;
    background-size: auto 950px,
                     auto,
                     auto 600px,
                     auto,
                     auto 260px;
  }

  body[path="${vars.pages.nfts}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring6}),
                      url(${ring7}),
                      url(${ring5}),
                      url(${ring4}),
                      url(${ring2});
    background-position: top 100px left -20px,
                         top 550px right,
                         top 650px left -10px,
                         top 3050px right,
                         top 3500px left;
    background-size: auto,
                     auto 500px,
                     auto 280px,
                     auto 300px,
                     auto 500px;
  }

  body[path="${vars.pages.ourPartners}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring3}),
                      url(${ring4}),
                      url(${ring10});
    background-position: top 150px left,
                         top 300px right -50px,
                         top 1100px left;
    background-size: auto,
                     auto,
                     auto;
  }

  body[path="${vars.pages.roadmap}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring11}),
                      url(${ring6});
    background-position: top 60px right -10px,
                         top 220px left;
    background-size: auto,
                     auto 600px;
  }

  body[path="${vars.pages.termsAndPrivacy}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring3}),
                      url(${ring4}),
                      url(${ring10}),
                      url(${ring4});
    background-position: top 150px left,
                         top 300px right,
                         top 1100px left,
                         top 2200px right;
    background-size: auto,
                     auto,
                     auto,
                     auto 260px;
  }

  body[path="${vars.pages.videos}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring3}),
                      url(${ring4});
    background-position: top 100px left,
                         top 100px right;
    background-size: auto,
                     auto;
  }

  body[path="${vars.pages.games}"] #___gatsby {
    background-repeat: no-repeat;
    background-image: url(${ring2}),
                      url(${ring3}),
                      url(${ring11}),
                      url(${ring5}),
                      url(${ring5}),
                      url(${ring6}),
                      url(${ring7});
    background-position: top 1050px left,
                         top 2200px left,
                         top 3300px right,
                         top 4050px left -90px,
                         top 6750px left,
                         top 6850px left -55px,
                         top 6700px right -60px;
    background-size: auto,
                     auto,
                     auto,
                     auto,
                     auto,
                     auto;
  }
`;

export default css;
