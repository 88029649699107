import React from 'react';
import { Root } from './style';

const Section = ({ section: { identifier, name, columns } }) => {
  const onHeaderClick = () => {
    const header = document.querySelector('header[data-section]');
    header && header.removeAttribute('data-section');
  };

  return (
    <Root data-menu={identifier}>
      <h4 onClick={onHeaderClick}>{name}</h4>
      <div className="wrapper">
        {columns.map((column, index) => (
          <ul key={index}>
            {column.name ? (
              <li>
                <strong>{column.name}</strong>
              </li>
            ) : (
              <li className="empty" />
            )}
            {column.links.map(
              ({ label, url, description, quicklink }, index) => {
                if (quicklink) {
                  return (
                    <li key={index} className="quicklink">
                      <a href={url}>{label}</a>
                    </li>
                  );
                } else {
                  return (
                    <li key={index}>
                      <a href={url}>{label}</a>
                      {description ? <p>{description}</p> : ''}
                    </li>
                  );
                }
              }
            )}
          </ul>
        ))}
      </div>
    </Root>
  );
};

export default Section;
