import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  text-align: center;

  li {
    max-width: 650px;
    margin: 12px 0;
    padding: 12px;
    display: flex;
    cursor: pointer;
    align-items: center;
    background: rgb(25, 26, 50);
    border-radius: 7px;
  }

  li:hover {
    background: rgb(18, 19, 37);
  }

  li:hover img {
    background: rgb(12, 14, 36);
  }

  img {
    width: 60px;
    margin-left: auto;
    padding: 12px;
    background: ${vars.colors.marineBlue};
    border-radius: 7px;
  }

  .strong {
    margin-left: 20px;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  .controls {
    display: flex;
    align-items: center;
    background: rgb(15, 18, 36);
    border: 2px solid rgb(66, 68, 121);
    border-radius: 8px;
  }

  .controls label,
  .controls input {
    font-weight: bold;
    text-transform: uppercase;
  }

  .controls label {
    margin-left: 5px;
  }

  .controls input {
    width: 35px;
    margin-left: auto;
    color: white;
    font-family: Jost;
    text-align: right;
    background: none;
    border: 0;
  }

  .notification {
    height: 15px;
    margin-top: 10px;
    margin-bottom: 30px;
    display: block;
  }

  .wallet {
    margin: auto;
    display: inline-flex;
  }

  .wallet strong {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: rgb(66, 68, 121);
  }

  .wallet .button {
    color: white;
    background: rgb(66, 68, 121);
  }

  .tx {
    padding-left: 30px;
    padding-right: 30px;
  }

  .busy {
    opacity: 0.5;
  }

  .busy .button {
    cursor: default;
  }

  ${vars.desktop.mediaQuery} {
    & {
      width: 600px;
    }

    h2 {
      padding: 20px 0 30px;
      font-size: 2.5rem;
    }

    .error h2 {
      padding: 10px 0 30px;
      font-size: 3.65rem;
    }

    .controls label {
      padding-top: 1px;
      padding-left: 18px;
      font-size: 0.8rem;
      line-height: 525%;
    }

    .controls input {
      margin-right: 10px;
    }

    .controls .button {
      margin: 15px;
      padding: 15px 20px 15px 45px;
    }

    .disconnect,
    .wallet {
      font-size: 0.7rem;
    }

    .connect {
      padding: 20px 60px;
      font-size: 0.775rem;
    }

    .wallet strong {
      width: 120px;
    }

    .disconnect,
    .wallet strong {
      margin-right: 20px;
      line-height: 425%;
      padding: 0 20px;
      border-radius: 9px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      width: calc(100vw - 80px);
    }

    h2 {
      padding: 10px 0 15px;
      font-size: 1.65rem;
    }

    .button {
      margin-bottom: 15px;
      padding: 12px 20px;
    }

    .connect {
      padding: 4px 9px;
      font-size: 0.585rem;
      border-radius: 5px;
    }

    .controls label {
      padding-left: 8px;
    }

    .controls .button {
      margin: 7px;
      padding: 10px 15px 10px 35px;
      background-position: center left 12px;
    }

    .controls input {
      margin-right: 5px;
      font-size: 0.7rem;
    }

    .wallet strong {
      width: 80px;
    }

    .disconnect,
    .wallet strong {
      margin-right: 20px;
      line-height: 425%;
      padding: 0 20px;
      border-radius: 9px;
    }

    .wallet {
      padding: 0 20px 20px 20px;
      display: block;
      margin: 0 auto;
      overflow: auto;
    }

    .wallet strong {
      float: left;
    }

    .wallet .disconnect {
      margin: 0;
      float: right;
    }

    .tx {
      margin-top: 12px;
      margin-bottom: 30px;
      display: inline-block;
    }
  }
`;

const Message = styled.div`
  &.error {
    text-align: center;
  }

  li {
    display: flex;
    cursor: pointer;
    align-items: center;
    background: rgb(25, 26, 50);
  }

  li.hr {
    height: 1px;
    padding: 0;
    cursor: default;
    display: block;
    line-height: 1px;
    text-align: center;
    background: rgb(51, 53, 80);
  }

  li.hr:hover {
    cursor: default;
    background: rgb(51, 53, 80);
  }

  li.hr span {
    padding: 0 12px;
    display: inline-block;
    background: rgb(33, 35, 65);
  }

  li:hover {
    background: rgb(18, 19, 37);
  }

  li:hover img {
    background: rgb(12, 14, 36);
  }

  .strong {
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  img {
    margin-left: auto;
    background: ${vars.colors.marineBlue};
    border-radius: 7px;
  }

  ${vars.desktop.mediaQuery} {
    &.error {
      width: 600px;
    }

    &.error h2 {
      padding: 10px 0 30px;
      font-size: 3.6rem;
    }

    &.error .button {
      margin-bottom: -20px;
    }

    li {
      max-width: 650px;
      margin: 12px 0;
      padding: 12px;
      border-radius: 7px;
    }

    li.hr {
      max-width: 662px;
      margin: 40px 6px;
    }

    li.hr span {
      font-size: 1.15rem;
    }

    .strong {
      margin-left: 20px;
      font-size: 1.4rem;
    }

    img {
      width: 60px;
      max-height: 60px;
      padding: 12px;
    }
  }

  ${vars.mobile.mediaQuery} {
    &.error {
      width: calc(100vw - 70px);
    }

    &.error h2 {
      padding: 10px 0 30px;
      font-size: 1.9rem;
    }

    &.error .button {
      margin-bottom: 30px;
    }

    li {
      margin: 9px 0;
      padding: 6px;
      border-radius: 5px;
    }

    li.hr {
      width: calc(100% - 12px);
      margin: 30px 6px;
    }

    li.hr span {
      font-size: 0.8rem;
    }

    .strong {
      margin-left: 10px;
      font-size: 0.85rem;
    }

    img {
      width: 25px;
      max-height: 25px;
      padding: 5px;
    }
  }
`;

export { Root, Message };
