import styled from "styled-components";

import vars from "variables";

import left from "assets/Layout/Header/Menu/Section/left.svg";
import rightBlue from "assets/Layout/Header/Menu/Section/right-blue.svg";
import toprightBlue from "assets/Layout/Header/Menu/Section/topright-blue.svg";

const Root = styled.div`
  pointer-events: auto;
  position: absolute;
  background: #f8fafb;
  z-index: 4;

  * {
    pointer-events: auto;
  }

  strong {
    margin-bottom: 6px;
    color: #bebebe;
    display: block;
    text-transform: uppercase;
    font-size: 0.625rem;
  }

  a {
    padding-right: 20px;
    color: black;
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
    background: url(${rightBlue}) no-repeat right center;
  }

  p {
    padding-bottom: 25px;
    color: #888;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  li.quicklink {
    margin-top: -1px;
  }

  li.quicklink a {
    padding-right: 18px;
    background-image: url(${toprightBlue});
  }

  ${vars.desktop.mediaQuery} {
    & {
      left: 0;
      width: calc(100% - 220px);
      padding: 45px 0 20px 220px;
    }

    h4 {
      display: none;
    }

    .wrapper {
      display: flex;
    }

    ul {
      width: 320px;
      padding-right: 75px;
    }

    li.empty {
      height: 34px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      top: 55px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 55px);
      position: absolute;
      transform-origin: 0% 0%;
      transform: translate(-100vw, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    h4 {
      width: calc(100vw - 65px);
      margin: 0 auto;
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 25px;
      color: black;
      display: block;
      font-size: 0.8rem;
      font-weight: 600;
      line-height: 65px;
      background: url(${left}) no-repeat left center;
      background-size: 6px;
      border-bottom: 1px solid rgb(245, 241, 241);
    }

    .wrapper {
      max-height: calc(100vh - 175px);
      overflow-y: scroll;
    }

    .wrapper ul {
      padding: 0 20px;
    }

    .wrapper ul li strong {
      padding-top: 10px;
    }
  }
`;

export { Root };
