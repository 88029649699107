import React from 'react';
import { Root } from './style';

import NavBar from './NavBar';
import Section from './Section';

import vars from 'variables';

const Menu = () => {
  return (
    <Root className="scalable">
      <NavBar />
      {vars.navigation.map((section) => (
        <Section key={section.identifier} section={section} />
      ))}
    </Root>
  );
};

export default Menu;
