import styled from "styled-components";

import open from "assets/Layout/Chatbot/open.png";
import close from "assets/Layout/Chatbot/close.svg";

import vars from "variables";

const Root = styled.div`
  & {
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 99999999;
  }

  &.visible .open {
    visibility: hidden;
    opacity: 0;
  }

  &.visible .chatbot {
    visibility: visible;
    overflow: hidden;
    opacity: 1;
  }

  &.compact .chatbot {
    max-height: 215px !important;
  }

  &.compact .chatbot .messages {
    overflow: hidden;
  }

  &.compact .options {
    display: none;
  }

  &.compact .overlay {
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block;
    position: absolute;
    z-index: 999;
  }

  &.compact .more {
    display: block;
    text-align: right;
  }

  &.compact .more span {
    margin-right: 17px;
    padding: 5px 13px;
    display: inline-block;
    font-weight: bold;
    font-size: 0.7rem;
    text-transform: uppercase;
    background: ${vars.colors.blue};
    border-radius: 3px;
  }

  .more,
  .overlay {
    display: none;
  }

  .scalable {
    transform-origin: top right;
  }

  .close {
    cursor: pointer;
    position: absolute;
    background: url(${close}) no-repeat;
    background-size: 100%;
    z-index: 9999;
  }

  .open {
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    position: absolute;
    transition: visibility 0.05s linear, opacity 0.2s linear;
    background: url(${open}) no-repeat;
    background-size: 100%;
  }

  .chatbot {
    bottom: 15px;
    right: 15px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: visibility 0.05s linear, opacity 0.2s linear;
    background: #455777;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px rgba(100, 100, 100, 0.5);
  }

  .chatbot .header {
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    background: ${vars.colors.chatPurple};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .chatbot i {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    background: #4af6a9;
    border-radius: 50px;
  }

  .chatbot .header > div {
    margin-left: auto;
  }

  .chatbot .messages {
    padding-bottom: 10px;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  ${vars.desktop.mediaQuery} {
    &.compact .chatbot .messages {
      height: 85px;
    }

    .close {
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
    }

    .open {
      width: 90px;
      height: 90px;
    }

    .chatbot {
      width: 400px;
      height: 600px;
    }

    .chatbot .header {
      height: 60px;
    }

    .chatbot .messages {
      height: 530px;
    }
  }

  ${vars.mobile.mediaQuery} {
    &.compact .chatbot .messages {
      height: 100px;
    }

    .close {
      top: 17px;
      right: 18px;
      width: 15px;
      height: 15px;
    }

    .open {
      width: 60px;
      height: 60px;
    }

    .chatbot {
      width: calc(100vw - 50px);
      height: calc(100vh - 120px);
    }

    .chatbot .header {
      height: 50px;
    }

    .chatbot .messages {
      height: calc(100vh - 60px - 50px);
    }
  }
`;

export { Root };
