import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  display: flex;
  letter-spacing: 0.5px;

  .wallet {
    margin-left: auto;
    display: inline-block;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: ${vars.colors.marineBlue};
  }

  body[path^='${vars.pages.claim}'] & .hidable {
    display: none !important;
  }

  ${vars.desktop.mediaQuery} {
    .disconnect,
    .wallet {
      font-size: 0.775rem;
    }

    .disconnect {
      padding: 20px 60px;
    }

    .wallet {
      width: 120px;
      margin-right: 20px;
      padding: 20px 30px;
      border-radius: 9px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .disconnect,
    .wallet {
      padding: 4px 9px;
      font-size: 0.585rem;
      border-radius: 5px;
    }

    .wallet {
      width: 60px;
      margin-right: 10px;
    }
  }
`;

const Message = styled.div`
  &.error {
    text-align: center;
  }

  .highlight {
    font-weight: 600;
    color: #0098ff;
  }

  li {
    display: flex;
    cursor: pointer;
    align-items: center;
    background: rgb(25, 26, 50);
  }

  li.hr {
    height: 1px;
    padding: 0;
    cursor: default;
    display: block;
    line-height: 1px;
    text-align: center;
    background: rgb(51, 53, 80);
  }

  li.hr:hover {
    cursor: default;
    background: rgb(51, 53, 80);
  }

  span {
    padding: 0 12px;
    display: inline-block;
    background: rgb(51, 53, 80);
    height: 2px;
    width: 100%;
    margin-bottom: 1rem;
  }

  li:hover {
    background: rgb(18, 19, 37);
  }

  li:hover img {
    background: rgb(12, 14, 36);
  }

  strong {
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  img {
    margin-left: auto;
    background: ${vars.colors.marineBlue};
    border-radius: 7px;
  }

  table {
    font-size: 1.2rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  td:first-of-type {
    padding-right: 2rem;
    text-align: right;
  }

  .bold {
    font-weight: bold;
  }

  select {
    background-color: rgb(0, 152, 255);
    padding: 5px 8px;
    font-size: 1rem;
    color: rgb(0, 3, 23);
    display: block;
    cursor: pointer;
    font-family: Jost, Helvetica Neue, Arial, sans-serif;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    background: white;
    border: none;
    min-width: 6rem;
    margin-right: 20px;
  }

  label {
    display: inline-block;
    font-size: 0.775rem;
    font-family: Jost, Helvetica Neue, Arial, sans-serif;
  }

  .vstack {
    margin-bottom: 1rem;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .button-small {
    padding: 6px 16px;
    font-size: 1rem;
  }

  input {
    background: rgb(25, 26, 50);
    color: rgb(217, 217, 217);
    font-size: 1.2rem;
    border: 1px solid rgb(237, 237, 237);
    padding: 2px;
    max-width: 200px;
  }

  .error {
    color: #c50000;
    font-size: 1rem;
    text-align: center;
  }

  ${vars.desktop.mediaQuery} {
    &.error {
      width: 600px;
    }

    &.error h2 {
      padding: 10px 0 30px;
      font-size: 3.6rem;
    }

    &.error .button {
      margin-bottom: -20px;
    }

    li {
      width: 650px;
      margin: 12px 0;
      padding: 12px;
      border-radius: 7px;
    }

    li.hr {
      width: 662px;
      margin: 40px 6px;
    }

    span {
      font-size: 1.15rem;
    }

    strong {
      margin-left: 20px;
      font-size: 1.4rem;
    }

    img {
      width: 60px;
      max-height: 60px;
      padding: 12px;
    }
  }

  ${vars.mobile.mediaQuery} {
    &.error {
      width: calc(100vw - 70px);
    }

    &.error h2 {
      padding: 10px 0 30px;
      font-size: 1.9rem;
    }

    &.error .button {
      margin-bottom: 30px;
    }

    li {
      margin: 9px 0;
      padding: 6px;
      border-radius: 5px;
    }

    li.hr {
      width: calc(100% - 12px);
      margin: 30px 6px;
    }

    span {
      font-size: 0.8rem;
    }

    strong {
      margin-left: 10px;
      font-size: 0.85rem;
    }

    img {
      width: 25px;
      max-height: 25px;
      padding: 5px;
    }

    table {
      font-size: 0.775rem;
    }

    td:first-of-type {
      padding-right: 1rem;
    }

    .button-small {
      font-size: 0.64rem;
    }

    input {
      font-size: 0.775rem;
      max-width: 100%;
    }

    select {
      font-size: 0.775rem;
    }

    .disconnect {
      margin-bottom: 2rem;
    }

    .error {
      font-size: 0.775rem;
    }
  }
`;

export { Root, Message };
