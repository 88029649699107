import React, { useEffect, useRef, useState } from "react";

import { globalHistory } from "@reach/router";
import { navigate } from "gatsby";
import moment from "moment";

import Fade from "react-reveal/Fade";

import { Root } from "./style";

import crypto from "crypto";
import Message from "./Message";

import { localStorageGet } from "utils";

const Chatbot = () => {
  const ref = useRef();

  let timeout;

  const [interacted, setInteracted] = useState(false);
  const [messages, setMessages] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const checkPopup = () => {
      setTimeout(() => {
        if (
          Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          ) > 900
        ) {
          if (
            meetsConditions() &&
            !window.location.pathname.match("/claim") &&
            !window.sessionStorage.getItem("chatbotInteracted")
          ) {
            timeout = setTimeout(() => {
              setVisible(true);
              setMessages([]);
              addMessage("START");
            }, 3000);
          }
        }
      }, 500);
    };

    checkPopup();

    return globalHistory.listen(({ action }) => {
      action === "PUSH" && checkPopup();
    });
  }, []);

  const meetsConditions = () => {
    const history = localStorageGet("history") || [];
    const now = Math.floor(Date.now() / 1000);

    return (
      history.length &&
      now - parseInt(history[0].split(" ")[0]) >= 60 &&
      [...new Set(history.map((page) => page.split(" ")[1]))].length >= 3
    );
  };

  const addMessage = (message) => {
    const link = (message.match(/https?:\/\/[^\s]+/) || [])[0];

    if (link) {
      const url = new URL(link);
      if (url.hostname === window.location.hostname) {
        navigate(url.pathname + url.hash, { replace: true });
      } else {
        window.open(link, "_blank").focus();
      }
      setTimeout(() => {
        // if (navigator.userAgentData.mobile) {
        toggle();
        // } else {
        //   addMessage('START');
        // }
      }, 350);
    } else {
      setMessages((messages) => [
        ...messages,
        {
          id: crypto.randomBytes(4).toString("hex"),
          time: moment().format("LT"),
          message,
        },
      ]);
      scroll();
    }
  };

  const scroll = () => {
    setTimeout(() => {
      ref.current.scrollTop = ref.current.scrollHeight;
    }, 200);
  };

  const toggle = (delay) => {
    timeout && clearTimeout(timeout);

    setTimeout(() => {
      window.sessionStorage.setItem("chatbotInteracted", true);
      setInteracted(true);
    }, delay);

    setVisible(!visible);

    if (!visible) {
      setMessages([]);
      addMessage("START");
    }
  };

  const overlayClick = () => {
    setInteracted(true);
  };

  return (
    <Root
      className={`${visible ? "visible" : ""} ${interacted ? "" : "compact"}`}
    >
      <div className="scalable">
        <div className="open" onClick={toggle} />
        <div className="chatbot">
          <div className="header">
            <i />
            <strong>Chatting with HODL</strong>
            <div>
              <div className="close" onClick={() => toggle(250)} />
            </div>
          </div>
          <div className="overlay" onClick={overlayClick} />
          <ul ref={ref} className="messages">
            {messages.map(({ id, time, message }) => (
              <Message
                key={id}
                time={time}
                identifier={message}
                addMessage={addMessage}
                scroll={scroll}
              />
            ))}
          </ul>
          <Fade left when={visible} delay={500} duration={250}>
            <div className="more">
              <span>Hi, please help me</span>
            </div>
          </Fade>
        </div>
      </div>
    </Root>
  );
};

export default Chatbot;
