import React from "react";
import { Root, QuickLinks, Socials, Links } from "./style";

import { Link } from "gatsby";

import vars from "variables";

import logo from "assets/Common/Logo/hodl.svg";

import coinmarketcap from "assets/Layout/Footer/Menu/coinmarketcap.svg";
import discord from "assets/Layout/Footer/Menu/discord.svg";
import facebook from "assets/Layout/Footer/Menu/facebook.svg";
import instagram from "assets/Layout/Footer/Menu/instagram.svg";
import reddit from "assets/Layout/Footer/Menu/reddit.svg";
import telegram from "assets/Layout/Footer/Menu/telegram.svg";
import tiktok from "assets/Layout/Footer/Menu/tiktok.svg";
import twitter from "assets/Layout/Footer/Menu/twitter.svg";
import youtube from "assets/Layout/Footer/Menu/youtube.svg";

const Menu = () => {
  return (
    <Root className="body-width">
      <QuickLinks>
        <Link className="logo" to={vars.pages.index}>
          <img src={logo} alt="Logo" />
        </Link>
        <Socials className="desktop">
          <a href={vars.links.telegram} target="_blank" rel="noreferrer">
            <img src={telegram} alt="Telegram" />
          </a>
          <a href={vars.links.discord} target="_blank" rel="noreferrer">
            <img src={discord} alt="Discord" />
          </a>
          <a href={vars.links.twitter} target="_blank" rel="noreferrer">
            <img src={twitter} alt="Twitter" />
          </a>
          <a href={vars.links.reddit} target="_blank" rel="noreferrer">
            <img src={reddit} alt="Reddit" />
          </a>
          <a href={vars.links.facebook} target="_blank" rel="noreferrer">
            <img src={facebook} alt="Facebook" />
          </a>
          <a href={vars.links.instagram} target="_blank" rel="noreferrer">
            <img src={instagram} alt="Instagram" />
          </a>
          <a href={vars.links.tiktok} target="_blank" rel="noreferrer">
            <img src={tiktok} alt="Tiktok" />
          </a>
          <a
            href="https://coinmarketcap.com/community/profile/HODLofficial/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={coinmarketcap} alt="Coinmarketcap" />
          </a>
          <a href={vars.links.youtube} target="_blank" rel="noreferrer">
            <img src={youtube} alt="Youtube" />
          </a>
        </Socials>
      </QuickLinks>
      <Links>
        {vars.navigation.map((section) => (
          <div
            key={section.identifier}
            style={{
              flex: `0 0 calc(${100 / vars.navigation.length}% - 24px)`,
            }}
          >
            <strong>{section.name}</strong>
            {section.columns.map((column) =>
              column.links.map((link) =>
                link.quicklink ? (
                  ""
                ) : (
                  <a key={`${column.name}:${link.label}`} href={link.url}>
                    {link.label}
                  </a>
                )
              )
            )}
          </div>
        ))}
      </Links>
      <QuickLinks className="mobile">
        <Socials>
          <a href={vars.links.telegram} target="_blank" rel="noreferrer">
            <img src={telegram} alt="Telegram" />
          </a>
          <a href={vars.links.discord} target="_blank" rel="noreferrer">
            <img src={discord} alt="Discord" />
          </a>
          <a href={vars.links.twitter} target="_blank" rel="noreferrer">
            <img src={twitter} alt="Twitter" />
          </a>
          <a href={vars.links.reddit} target="_blank" rel="noreferrer">
            <img src={reddit} alt="Reddit" />
          </a>
          <a href={vars.links.facebook} target="_blank" rel="noreferrer">
            <img src={facebook} alt="Facebook" />
          </a>
          <a href={vars.links.instagram} target="_blank" rel="noreferrer">
            <img src={instagram} alt="Instagram" />
          </a>
          <a href={vars.links.tiktok} target="_blank" rel="noreferrer">
            <img src={tiktok} alt="Tiktok" />
          </a>
          <a
            href="https://coinmarketcap.com/community/profile/HODLofficial/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={coinmarketcap} alt="Coinmarketcap" />
          </a>
          <a href={vars.links.youtube} target="_blank" rel="noreferrer">
            <img src={youtube} alt="Youtube" />
          </a>
        </Socials>
      </QuickLinks>
      <div className="legal">
        <div>
          {vars.legalLinks.map((link) => (
            <a key={link.label} href={link.url}>
              {link.label}
            </a>
          ))}
        </div>
        <a
          className="copyright"
          {...(vars.links.hodlverseinc
            ? { href: vars.links.hodlverseinc }
            : {})}
        >
          &copy; HODLVERSE Inc
        </a>
      </div>
    </Root>
  );
};

export default Menu;
