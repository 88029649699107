import styled from "styled-components";

import vars from "variables";

const Root = styled.main`
  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    & {
      overflow-x: hidden;
    }

    body[path^="${vars.pages.claim}"] & {
      margin-top: -100px;
      padding-top: 100px;
    }

    body[path^="/dashboard"] & {
      min-height: 450px;
    }
  }
`;

export { Root };
