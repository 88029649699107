import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  text-align: center;
  position: fixed;
  z-index: 5;

  &.show {
    display: block;
  }

  .progress {
    top: 0;
    left: 0;
    height: 10px;
    background-color: #098ff2;
    position: fixed;
    z-index: 6;
  }

  .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    position: absolute;
    background: black;
  }

  h2 {
    top: 0;
    left: 0;
    width: 100%;
    line-height: 100vh;
    position: absolute;
    z-index: 5;
  }

  @keyframes dots-1 {
    from {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
  }

  @keyframes dots-2 {
    from {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes dots-3 {
    from {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
  }

  @-webkit-keyframes dots-2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes dots-3 {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  .dots {
    display: inline-block;
    padding-left: 8px;
  }

  .dots span {
    animation-name: dots-1;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(1);

    -webkit-animation-name: dots-1;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-timing-function: steps(1);
    -webkit-animation-iteration-count: infinite;
  }

  @-webkit-keyframes dots-1 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  .dots span:first-child + span {
    animation-name: dots-2;
    -webkit-animation-name: dots-2;
  }

  .dots span:first-child + span + span {
    animation-name: dots-3;
    -webkit-animation-name: dots-3;
  }

  ${vars.desktop.mediaQuery} {
    h2 {
      font-size: 1.75rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    h2 {
      font-size: 0.85rem;
    }
  }
`;

export { Root };
