import React, { useEffect, Fragment, useRef } from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';
import { useSiteData } from 'utils';
import { Helmet } from 'react-helmet';
import { useWeb3 } from 'web3';
import LoadingOverlay from 'components/Common/LoadingOverlay';

import Menu from './Menu';

const Header = () => {
  const siteData = useSiteData();
  const page = useState(null);

  useEffect(() => {
    document.addEventListener('click', ({ target }) => {
      const header = document.getElementsByTagName('header')[0];

      if (header.getAttribute('data-section')) {
        if (target.getAttribute('href') || !target.closest('header')) {
          header.removeAttribute('data-section');
        }
      }

      if (target.matches('#menu input[type=checkbox]')) {
        if (target.checked) {
          document.body.classList.add('noscroll');
        } else {
          header.removeAttribute('data-section');
          document.body.classList.remove('noscroll');
        }
      }
    });

    document.addEventListener('mousemove', ({ clientY, target }) => {
      const header = document.getElementsByTagName('header')[0];

      if (header.getAttribute('data-section')) {
        if (clientY > 530) {
          header.removeAttribute('data-section');
        }
      }
    });

    window.addEventListener('scroll', () => {
      document.body.classList[window.scrollY ? 'add' : 'remove']('fixed');
    });

    page.set(
      Object.values(siteData.pages).find(
        (page) => page.path === window.location.pathname
      )
    );
  }, []);

  const { isConnecting } = useWeb3();
  const loadingOverlay = useRef();

  useEffect(() => {
    if (isConnecting) {
      if (window.location.pathname !== '/claim')
        loadingOverlay.current.show('Connecting wallet');
    } else {
      loadingOverlay.current.cancel();
    }
  }, [isConnecting]);

  return (
    <Fragment>
      <Helmet>
        <title>
          {`HODL Token - ${
            (page.get() && page.get().title) || 'Passive Income Made Easy'
          }`}
        </title>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Root>
        <LoadingOverlay usedRef={loadingOverlay} />
        <Menu />
      </Root>
    </Fragment>
  );
};

export default Header;
