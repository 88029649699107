import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  text-transform: uppercase;

  .legal div a {
    color: ${vars.colors.grey};
    font-weight: 500;
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding: 75px 0;
    }

    span {
      font-size: 0.775rem;
    }

    .legal {
      display: flex;
    }

    .legal div a {
      margin-right: 30px;
      font-size: 0.7rem;
      letter-spacing: 0.75px;
    }

    .copyright {
      margin-left: auto;
      font-size: 0.775rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      padding: 30px 20px;
    }

    span {
      font-weight: 500;
    }

    .legal div {
      display: flex;
      justify-content: space-between;
    }

    .legal div a {
      font-size: 0.575rem;
      letter-spacing: 0.5px;
    }

    .copyright {
      margin-top: 15px;
      padding-right: 1px;
      display: block;
      font-size: 0.575rem;
      text-align: right;
    }
  }
`;

const QuickLinks = styled.div`
  display: flex;

  ${vars.desktop.mediaQuery} {
    .logo img {
      width: 105px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .logo img {
      width: 90px;
    }
  }
`;

const Socials = styled.div`
  padding-bottom: 30px;
  text-align: right;

  ${vars.desktop.mediaQuery} {
    & {
      margin-left: auto;
    }

    a {
      margin-left: 10px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-top: 50px;
      margin-bottom: 5px;
      margin-left: auto;
      margin-right: auto;
    }

    a {
      margin-right: 10px;
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 275%;

  strong {
    color: #41416c;
  }

  div a {
    display: block;
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding: 20px 0 60px;
      font-size: 0.775rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    div {
      padding-top: 35px;
      flex: 0 0 calc(50% - 14px) !important;
      font-weight: 500;
      line-height: 350%;
    }

    strong {
      padding-bottom: 6px;
      display: block;
      font-size: 0.725rem;
    }
  }
`;

export { Root, QuickLinks, Socials, Links };
