import React, { useEffect, useRef, Fragment } from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';
import usePortal from 'react-useportal';
import ReactModal from 'react-modal';

import Modal from 'components/Common/Modal';
import BuyNFTs from 'components/Common/Modal/BuyNFTs';

import Body from './Body';
import Chatbot from './Chatbot';
import Footer from './Footer';
import GlobalStyle from './GlobalStyle';
import Header from './Header';

import vars from 'variables';

const Layout = ({ children }) => {
  const { Portal } = usePortal({
    bindTo:
      typeof document !== 'undefined' && document.getElementById('pregatsby'),
  });

  const script = useRef();
  const modal = useRef();
  const state = useState({ modal: null });

  useEffect(() => {
    const javascript = document.createRange().createContextualFragment(`
      <!-- ***** Google Analytics PIXEL ***** -->

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-201339426-1"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-201339426-1');
      </script>

      <!-- ***** AdRoll PIXEL ***** -->

      <script type="text/javascript">
        adroll_adv_id = "HTRHAFMFWVB2TESZW4PPYO";
        adroll_pix_id = "M4M3FCKJM5FNHD2E5OU3MX";
        adroll_version = "2.0";

        (function(w, d, e, o, a) {
          w.__adroll_loaded = true;
          w.adroll = w.adroll || [];
          w.adroll.f = [ 'setProperties', 'identify', 'track' ];
          var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js";
          for (a = 0; a < w.adroll.f.length; a++) {
            w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
              return function() {
                w.adroll.push([ n, arguments ])
              }
            })(w.adroll.f[a])
          }
          e = d.createElement('script');
          o = d.getElementsByTagName('script')[0];
          e.async = 1;
          e.src = roundtripUrl;
          o.parentNode.insertBefore(e, o);
        })(window, document);

        adroll.track("pageView");
      </script>
    `);
    if (script.current) {
      script.current.appendChild(javascript);
    }
    const scale = () => {
      const scalable = document.querySelectorAll('.scalable');
      const width = window.innerWidth;
      if (width > vars.mobileWidth && width < vars.scaleWidth) {
        let ratio = Math.min(width / vars.scaleWidth, 1);
        scalable.forEach((el) => {
          el.style.transform = `scale(${ratio})`;
          el.classList.add('scaled');
          el.parentElement.style.height = `${
            Math.ceil(el.scrollHeight * ratio) + 3
          }px`;
        });
      } else {
        scalable.forEach((el) => {
          el.removeAttribute('style');
          el.classList.remove('scaled');
          el.parentElement.removeAttribute('style');
        });
      }
    };
    ReactModal.setAppElement('#___gatsby');
    window.addEventListener('resize', scale);
    setTimeout(scale, 250);
    window.addEventListener('click', (event) => {
      const mod = event.target.getAttribute('data-modal');
      if (mod) {
        event.preventDefault();
        state.modal.set(mod);
        modal.current.show();
      }
    });
  }, []);

  return (
    <Fragment>
      <Portal>
        <Header />
      </Portal>
      <Root className="scalable">
        <GlobalStyle />
        <Body>{children}</Body>
        <Footer />
      </Root>
      <Chatbot />
      <Modal className="scaled-07" usedRef={modal}>
        {state.modal.get() === 'buyNFTs' ? (
          <BuyNFTs modal={modal.current} />
        ) : (
          ''
        )}
      </Modal>
      <div ref={script} />
    </Fragment>
  );
};

export default Layout;
