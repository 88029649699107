import React from 'react';
import { Root } from './style';

const Body = (props) => {
  return (
    <Root>
      {props.children}
    </Root>
  );
};

export default Body;
