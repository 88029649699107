exports.components = {
  "component---src-components-about-us-index-js": () => import("./../../../src/components/AboutUs/index.js" /* webpackChunkName: "component---src-components-about-us-index-js" */),
  "component---src-components-case-studies-index-js": () => import("./../../../src/components/CaseStudies/index.js" /* webpackChunkName: "component---src-components-case-studies-index-js" */),
  "component---src-components-case-study-index-js": () => import("./../../../src/components/CaseStudy/index.js" /* webpackChunkName: "component---src-components-case-study-index-js" */),
  "component---src-components-claim-index-js": () => import("./../../../src/components/Claim/index.js" /* webpackChunkName: "component---src-components-claim-index-js" */),
  "component---src-components-common-collection-page-index-js": () => import("./../../../src/components/Common/CollectionPage/index.js" /* webpackChunkName: "component---src-components-common-collection-page-index-js" */),
  "component---src-components-common-item-page-index-js": () => import("./../../../src/components/Common/ItemPage/index.js" /* webpackChunkName: "component---src-components-common-item-page-index-js" */),
  "component---src-components-common-simple-page-index-js": () => import("./../../../src/components/Common/SimplePage/index.js" /* webpackChunkName: "component---src-components-common-simple-page-index-js" */),
  "component---src-components-dashboard-addresses-index-js": () => import("./../../../src/components/DashboardAddresses/index.js" /* webpackChunkName: "component---src-components-dashboard-addresses-index-js" */),
  "component---src-components-dashboard-hodlers-index-js": () => import("./../../../src/components/DashboardHodlers/index.js" /* webpackChunkName: "component---src-components-dashboard-hodlers-index-js" */),
  "component---src-components-dashboard-index-js": () => import("./../../../src/components/Dashboard/index.js" /* webpackChunkName: "component---src-components-dashboard-index-js" */),
  "component---src-components-dashboard-reflections-index-js": () => import("./../../../src/components/DashboardReflections/index.js" /* webpackChunkName: "component---src-components-dashboard-reflections-index-js" */),
  "component---src-components-dashboard-reinvestments-index-js": () => import("./../../../src/components/DashboardReinvestments/index.js" /* webpackChunkName: "component---src-components-dashboard-reinvestments-index-js" */),
  "component---src-components-dashboard-rewards-index-js": () => import("./../../../src/components/DashboardRewards/index.js" /* webpackChunkName: "component---src-components-dashboard-rewards-index-js" */),
  "component---src-components-dashboard-transactions-index-js": () => import("./../../../src/components/DashboardTransactions/index.js" /* webpackChunkName: "component---src-components-dashboard-transactions-index-js" */),
  "component---src-components-exchanges-index-js": () => import("./../../../src/components/Exchanges/index.js" /* webpackChunkName: "component---src-components-exchanges-index-js" */),
  "component---src-components-fa-qs-index-js": () => import("./../../../src/components/FAQs/index.js" /* webpackChunkName: "component---src-components-fa-qs-index-js" */),
  "component---src-components-fundraising-index-js": () => import("./../../../src/components/Fundraising/index.js" /* webpackChunkName: "component---src-components-fundraising-index-js" */),
  "component---src-components-games-index-js": () => import("./../../../src/components/Games/index.js" /* webpackChunkName: "component---src-components-games-index-js" */),
  "component---src-components-gem-fighter-nf-ts-index-js": () => import("./../../../src/components/GemFighterNFTs/index.js" /* webpackChunkName: "component---src-components-gem-fighter-nf-ts-index-js" */),
  "component---src-components-gem-fighter-p-2-e-index-js": () => import("./../../../src/components/GemFighterP2E/index.js" /* webpackChunkName: "component---src-components-gem-fighter-p-2-e-index-js" */),
  "component---src-components-history-index-js": () => import("./../../../src/components/History/index.js" /* webpackChunkName: "component---src-components-history-index-js" */),
  "component---src-components-hodl-forward-case-index-js": () => import("./../../../src/components/HODLForwardCase/index.js" /* webpackChunkName: "component---src-components-hodl-forward-case-index-js" */),
  "component---src-components-hodl-forward-index-js": () => import("./../../../src/components/HODLForward/index.js" /* webpackChunkName: "component---src-components-hodl-forward-index-js" */),
  "component---src-components-hodl-ranks-index-js": () => import("./../../../src/components/HODLRanks/index.js" /* webpackChunkName: "component---src-components-hodl-ranks-index-js" */),
  "component---src-components-hodlx-exchanges-index-js": () => import("./../../../src/components/HODLXExchanges/index.js" /* webpackChunkName: "component---src-components-hodlx-exchanges-index-js" */),
  "component---src-components-index-index-js": () => import("./../../../src/components/Index/index.js" /* webpackChunkName: "component---src-components-index-index-js" */),
  "component---src-components-nf-ts-index-js": () => import("./../../../src/components/NFTs/index.js" /* webpackChunkName: "component---src-components-nf-ts-index-js" */),
  "component---src-components-our-partners-index-js": () => import("./../../../src/components/OurPartners/index.js" /* webpackChunkName: "component---src-components-our-partners-index-js" */),
  "component---src-components-roadmap-index-js": () => import("./../../../src/components/Roadmap/index.js" /* webpackChunkName: "component---src-components-roadmap-index-js" */),
  "component---src-components-video-index-js": () => import("./../../../src/components/Video/index.js" /* webpackChunkName: "component---src-components-video-index-js" */),
  "component---src-components-videos-index-js": () => import("./../../../src/components/Videos/index.js" /* webpackChunkName: "component---src-components-videos-index-js" */),
  "component---src-components-whitepaper-index-js": () => import("./../../../src/components/Whitepaper/index.js" /* webpackChunkName: "component---src-components-whitepaper-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

