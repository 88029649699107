import vars from "variables";

const css = `
  body {
    font-size: 0.925rem;
    overflow-x: hidden;
  }

  .body-width {
    width: ${vars.desktop.body.minWidth};
  }

  .mobile {
    display: none !important;
  }

  .scaled {
    transform-origin: top left;
  }

  .scaled-07 {
    transform: scale(0.7);
  }

  h1 {
    font-size: 5.85rem;
  }

  h1, h2 {
    line-height: 100%;
  }

  .rm-modal {
    padding: 50px 70px;
    border-radius: 12px;
  }

  .rm-modal h3 {
    padding: 0 20px 15px;
    font-size: 1.85rem;
    font-weight: 900;
  }

  .rm-close {
    width: 40px;
    height: 40px;
    margin-top: -35px;
    margin-right: -55px;
    margin-bottom: -4px;
    position: relative;
    z-index: 999999;
  }

  a.button {
    padding: 20px 16px;
    font-size: 0.775rem;
  }

  a.button.logo,
  a.button.logo-x {
    padding-left: 47px;
    background-position: 16px calc(50% - 1px);
  }

  a.button.blue.bsc {
    padding-left: 65px !important;
    background-position: 34px calc(50% - 1px);
  }

  a.button.blue.download {
    padding-left: 20px;
    padding-right: 50px;
    background-position: right 18px center;
  }

  a.button.blue.telegram {
    padding-left: 70px !important;
    padding-right: 45px;
    background-position: 38px calc(50% - 1px);
  }
`;

export default css;
