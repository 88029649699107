import styled from "styled-components";

import vars from "variables";

const Root = styled.header`
  position: relative;
  z-index: 4;

  body.fixed & {
    top: 0;
    left: 0;
    right: 0;
    display: block;
    position: fixed;
  }

  ${vars.desktop.mediaQuery} {
    & {
      pointer-events: none;
    }

    &[data-section] {
      background: white !important;
    }

    &[data-section] a[data-section] {
      color: ${vars.colors.black};
    }
  }

  ${vars.mobile.mediaQuery} {
    body[path^="${vars.pages.caseStudies}"] & {
      background: rgb(17, 20, 47);
    }
  }
`;

export { Root };
