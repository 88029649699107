import React, { useEffect } from 'react';
import { Root, Sections, Buttons } from './style';

import { Link } from 'gatsby';

import vars from 'variables';
import { useWeb3 } from 'web3';
import Wallet from './Wallet';
import Connect from './Connect';

const NavBar = () => {
  const showMenu = ({ target }) => {
    const section = target.getAttribute('data-section');
    target.closest('header').setAttribute('data-section', section);
  };

  const isClaimPage = () => window.location.pathname.startsWith('/claim');

  const { wallet } = useWeb3();

  function get_cookie(name) {
    return document.cookie.split(';').some((c) => {
      return c.trim().startsWith(name + '=');
    });
  }

  useEffect(() => {
    if (!wallet) {
      if (get_cookie('wallet')) {
        document.cookie = 'wallet=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
      }
    }
  }, [wallet]);

  return (
    <Root>
      <Link className="index-logo" to={vars.pages.index}></Link>
      <div id="menu">
        <div id="menu-buttons" />
        <input className="hidable" type="checkbox" id="menuCheckbox" />
        <span className="hidable"></span>
        <span className="hidable"></span>
        <span className="hidable"></span>
        <nav>
          <Link className="mobile index-logo" to={vars.pages.index}></Link>
          <Sections>
            {vars.navigation.map((section) => (
              <a
                key={section.identifier}
                role="navigate"
                onClick={showMenu}
                onMouseEnter={showMenu}
                data-section={section.identifier}
              >
                {section.name}
              </a>
            ))}
          </Sections>
          <Buttons>
            <a
              className="button logo hidable"
              href={vars.links.buy}
              target="_blank"
              rel="noreferrer"
            >
              Buy $HODL
            </a>
            <Link className="button blue hidable" to={vars.pages.claim}>
              Claim BNB
            </Link>
            {wallet ? <Wallet header /> : <Connect header />}
          </Buttons>
        </nav>
      </div>
    </Root>
  );
};

export default NavBar;
