import React, { useEffect } from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';
import ReactModal from 'react-modal';

import RawHTML from 'components/Common/RawHTML';

const Modal = ({ usedRef, className, children, onClose, blocked = false }) => {
  const state = useState({
    show: false,
    html: null,
  });

  useEffect(() => {
    usedRef.current = {
      show: (html) => {
        if (html) {
          state.html.set(html);
        }
        state.show.set(true);
      },
      hide: () => {
        state.show.set(false);
        onClose && setTimeout(onClose, 10);
      },
      state: state,
    };
  }, []);

  return (
    <Root>
      <ReactModal
        isOpen={state.show.get()}
        className={`rm-modal ${className}`}
        overlayClassName="rm-overlay"
      >
        <div
          className="rm-close"
          onClick={() => !blocked && usedRef.current.hide()}
        />
        {children}
        <RawHTML html={state.html.get()} />
      </ReactModal>
    </Root>
  );
};

export default Modal;
