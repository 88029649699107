import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import * as hookstate from "@hookstate/core";

import vars from "variables";

import { formatNumber, localStorageGet, localStorageSet } from "utils";

import {
  HODLXADDRESS,
  getClaimableBNB,
  getBnbUsdPrice,
  getHodlUsdPrice,
  getNextClaimDate,
  getReinvested,
} from "web3";

const DashboardContext = createContext({
  addresses: {},
  balance: 0,
  claimableBNB: 0,
  loadingOverlay: {},
  reinvestedHODL: 0,
  reinvestedHODLX: 0,
  reinvestments: {},
  reflected: 0,
  reflections: {},
  rewarded: 0,
  rewards: {},
  transactions: {},
});

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const addresses = hookstate.useState({});
  const [balance, setBalance] = useState(0);
  const [claimableBNB, setClaimableBNB] = useState(0);
  const [reinvestedHODL, setReinvestedHODL] = useState(0);
  const [reinvestedHODLX, setReinvestedHODLX] = useState(0);
  const [reinvestments, setReinvestments] = useState({});
  const [reflected, setReflected] = useState(0);
  const [reflections, setReflections] = useState({});
  const [rewarded, setRewarded] = useState(0);
  const [rewards, setRewards] = useState({});
  const [stats, setStats] = useState({});
  const [transactions, setTransactions] = useState({});

  const claimEpochs = hookstate.useState([]);
  const loadingOverlay = useRef();

  useEffect(() => {
    const data = localStorageGet("dashboardData", {});

    addresses.set(data.addresses || {});
    setBalance(data.balance || 0);
    setClaimableBNB(data.claimableBNB || 0);
    setReinvestedHODL(data.reinvestedHODL || 0);
    setReinvestedHODLX(data.reinvestedHODLX || 0);
    setReinvestments(data.reinvestments || {});
    setReflected(data.reflected || 0);
    setReflections(data.reflections || {});
    setRewarded(data.rewarded || 0);
    setRewards(data.rewards || {});
    setStats(data.stats || {});
    setTransactions(data.transactions || {});

    updateAll();
  }, []);

  const updateAll = () => {
    Object.entries(addresses.get()).forEach(([account, selected]) => {
      update(account);
    });
  };

  const update = async (address) => {
    if (address) {
      reinvestments[address] = await fetchTransactions(
        "reinvestments",
        address
      );
      reflections[address] = await fetchTransactions("reflections", address);
      rewards[address] = await fetchTransactions("rewards", address);
      stats[address] = Object.assign(await fetchData("stats", address), {
        claimableBNB: await getClaimableBNB(address),
        reinvestedHODLX: await getReinvested(address, HODLXADDRESS),
        reflected: reflections[address].reduce(
          (reflected, { amount }) => reflected + amount,
          0
        ),
      });
      transactions[address] = await fetchTransactions("transactions", address);
    }

    let accBalance = 0;
    let accClaimableBNB = 0;
    let accReinvestedHODL = 0;
    let accReinvestedHODLX = 0;
    let accReflected = 0;
    let accRewarded = 0;

    Object.entries(addresses.get()).forEach(([account, selected]) => {
      if (selected) {
        const accountStats = stats[account];
        accBalance += accountStats.balance;
        accClaimableBNB += accountStats.claimableBNB;
        accReinvestedHODL += accountStats.reinvested;
        accReinvestedHODLX += accountStats.reinvestedHODLX;
        accReflected += accountStats.reflected;
        accRewarded += accountStats.rewarded;
      }
    });

    setBalance(accBalance);
    setClaimableBNB(accClaimableBNB);
    setReinvestedHODL(accReinvestedHODL);
    setReinvestedHODLX(accReinvestedHODLX);
    setReflected(accReflected);
    setRewarded(accRewarded);

    fetchClaimEpochs();

    localStorageSet("dashboardData", {
      addresses: addresses.get(),
      balance: accBalance,
      claimableBNB: accClaimableBNB,
      reinvestedHODL: accReinvestedHODL,
      reinvestedHODLX: accReinvestedHODLX,
      reinvestments,
      reflected: accReflected,
      reflections,
      rewarded: accRewarded,
      rewards,
      stats,
      transactions,
    });
  };

  const addWalletAddress = (address) => {
    loadingOverlay.current.show("Getting dashboard info");
    address = address.toLowerCase();
    addresses[address].set(true);
    update(address);
  };

  const removeWalletAddress = (address) => {
    address = address.toLowerCase();
    if (Object.keys(addresses.get()).indexOf(address) !== -1) {
      addresses[address].set(hookstate.none);
      update();
    }
  };

  const selectWalletAddress = (address, selected) => {
    address = address.toLowerCase();
    if (Object.keys(addresses.get()).indexOf(address) !== -1) {
      addresses[address].set(selected);
      update();
    }
  };

  const fetchClaimEpochs = () => {
    setTimeout(() => {
      const epochs = [];
      Promise.all(
        Object.entries(addresses.get()).map(async ([address, selected]) => {
          if (selected) {
            epochs.push({
              address,
              epoch: Number(await getNextClaimDate(address)),
            });
          }
        })
      ).then(() => {
        claimEpochs.set(epochs.sort(({ epoch: a }, { epoch: b }) => a - b));
        loadingOverlay.current?.hide();
      });
    }, 250);
  };

  return (
    <DashboardContext.Provider
      value={{
        addresses,
        balance,
        claimableBNB,
        claimEpochs,
        loadingOverlay,
        reinvestments,
        reinvestedHODL,
        reinvestedHODLX,
        reflected,
        reflections,
        rewarded,
        rewards,
        transactions,
        addWalletAddress,
        removeWalletAddress: (address) => removeWalletAddress(address),
        selectWalletAddress: (address) => selectWalletAddress(address, true),
        deselectWalletAddress: (address) => selectWalletAddress(address, false),
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

const fetchTransactions = async (type, address) => {
  const getUsdPrice = {
    rewards: getBnbUsdPrice,
    reflections: getHodlUsdPrice,
    reinvestments: getHodlUsdPrice,
    transactions: getHodlUsdPrice,
  }[type];

  const usdPrice = await getUsdPrice();
  const txs = await fetchData(type, address);

  return txs.map(([block, transaction, amount, timestamp, from, to]) => ({
    amount: parseFloat(amount),
    value: "$" + formatNumber(amount * usdPrice, 2),
    timestamp:
      typeof timestamp === "number"
        ? new Date(timestamp * 1000).toISOString()
        : timestamp,
    tx: transaction?.toLowerCase() || "",
    block,
    type: from === address ? "OUT" : "IN",
  }));
};

const fetchData = (type, address) => {
  const versionPrefix = ["reflections", "transactions"].includes(type)
    ? "/v2"
    : "";

  return fetch(`${vars.apiHost}${versionPrefix}/-/${type}/${address}`)
    .then((response) => response.json())
    .then((data) => data);
};
