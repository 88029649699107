import React, { useEffect } from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';

const FASTCAP = 60;
const SLOWCAP = 85;
const BREAKCAP = 98;

const DEFAULT = 10000;
const DELAY = 30000;

const LoadingOverlay = ({ usedRef }) => {
  const state = useState({
    progress: 0,
    show: false,
    text: null,
  });

  let start, interval, startProgress;

  useEffect(() => {
    usedRef.current = {
      show: (text, duration) => {
        if (text) {
          state.text.set(text);
        }

        state.progress.set(0);
        state.show.set(true);

        duration = duration || DEFAULT;
        start = new Date().getTime();

        interval = setInterval(() => {
          try {
            const delta = new Date().getTime() - start;
            if (state.progress.get() < FASTCAP) {
              state.progress.set((delta / duration) * FASTCAP);
              if (state.progress.get() > FASTCAP) {
                start = new Date().getTime();
              }
            } else if (state.progress.get() < SLOWCAP) {
              state.progress.set(
                FASTCAP + (delta / DELAY) * (SLOWCAP - FASTCAP)
              );
              if (state.progress.get() > SLOWCAP) {
                start = new Date().getTime();
              }
            } else if (state.progress.get() < BREAKCAP) {
              state.progress.set(
                SLOWCAP + (delta / DELAY) * (BREAKCAP - SLOWCAP)
              );
            } else {
              clearInterval(interval);
            }
          } catch (error) {
            clearInterval(interval);
          }
        }, 50);
      },
      cancel: () => state.show.set(false),
      hide: () => {
        clearInterval(interval);

        interval = setInterval(() => {
          try {
            if (state.progress.get() < 100) {
              state.progress.set(state.progress.get() + 1);
            } else {
              clearInterval(interval);
              state.show.set(false);
            }
          } catch (error) {
            clearInterval(interval);
          }
        }, 10);
      },
      update: (text, hideAfter) => {
        if (text) {
          console.log('Text updated: ' + text);
          state.text.set(text);
        }

        if (hideAfter) {
          console.log('clearInterval');
          clearInterval(interval);

          start = new Date().getTime();
          startProgress = state.progress.get();

          interval = setInterval(() => {
            try {
              const delta = new Date().getTime() - start;
              if (state.progress.get() < 100) {
                state.progress.set(
                  startProgress + (delta / hideAfter) * (100 - startProgress)
                );
              } else {
                clearInterval(interval);
                state.show.set(false);
              }
            } catch (error) {
              clearInterval(interval);
              state.show.set(false);
            }
          }, 10);
        }
      },
    };
  }, []);

  return (
    <Root className={state.show.get() ? 'show' : ''}>
      <div
        className="progress"
        style={{ width: `${state.progress.get()}vw` }}
      />
      <div className="overlay"></div>
      <h2>
        {state.text.get()}
        <span className="dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </h2>
    </Root>
  );
};

export default LoadingOverlay;
