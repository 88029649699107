import React from "react";
import { Root } from "./style";

import Fade from "react-reveal/Fade";

import RawHTML from "components/Common/RawHTML";

import { useSiteData } from "utils";

const Message = ({ identifier, time, addMessage, scroll }) => {
  const { chatMessages } = useSiteData().resources;

  const { message, options, startAgain } = chatMessages.find(
    (message) => message.identifier === identifier
  );

  const buttons = options.split("\n");

  if (startAgain) {
    buttons.push("START: Back to start");
  }

  return (
    <Root>
      <Fade left delay={250} duration={250} onReveal={scroll}>
        <div>
          <strong>{time}</strong>
          <span className="arrow" />
          <RawHTML html={message} />
          <div className="options">
            {buttons.map((button, index) => {
              const [identifier, text] = button.split(": ");
              return (
                <Fade
                  key={index}
                  left
                  delay={index * 100}
                  duration={250}
                  onReveal={scroll}
                >
                  <div className={identifier}>
                    <span onClick={() => addMessage(identifier)}>{text}</span>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
      </Fade>
    </Root>
  );
};

export default Message;
