import vars from "variables";

const css = `
  body {
    font-size: 0.64rem;
  }

  body.fixed {
    padding-top: 50px;
  }

  body.noscroll {
    height: 100%;
    overflow: hidden;
  }

  .body-width {
    width: calc(100vw - 40px);
  }

  .desktop {
    display: none !important;
  }

  h1 {
    font-size: 3.85rem;
  }

  h1, h2 {
    line-height: 120%;
  }

  p {
    color: ${vars.colors.grey};
  }

  .rm-modal {
    width: calc(100% - 64px);
    padding: 15px 13px 5px;
    border-radius: 7px;
  }

  .rm-modal h3 {
    padding: 0 20px;
    font-size: 0.85rem;
    font-weight: 600;
  }

  .rm-close {
    width: 27px;
    height: 27px;
    margin-top: -8px;
    margin-right: -8px;
    position: relative;
    z-index: 999999;
  }

  a.button {
    padding: 15px 25px;
    font-size: 0.64rem;
    border-radius: 6px;
  }

  a.button.logo {
    padding-left: 44px;
    background-position: 22px center;
    background-size: 16px auto;
  }

  a.button.logo-x {
    padding-left: 44px;
    background-position: 22px center;
    background-size: 15px auto;
  }

  a.button.blue.bsc {
    padding-left: 49px !important;
    background-position: 25px 50%;
    background-size: 18px;
  }

  a.button.blue.download {
    padding-left: 17px;
    padding-right: 35px;
    background-position: right 13px center;
    background-size: 12px;
  }

  a.button.blue.telegram {
    padding-left: 45px !important;
    padding-right: 25px;
    background-position: 20px 50%;
    background-size: 16px;
  }
`;

export default css;
