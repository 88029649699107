import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  ${vars.desktop.mediaQuery} {
    & {
      min-width: ${vars.desktop.body.minWidth};
    }

    &.scaled {
      min-width: ${vars.scaleWidth}px;
    }

    .fixed & {
      padding-top: 120px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      padding-top: 30px;
    }

    body[path^="/dashboard"] & {
      padding-top: 0;
    }
  }
`;

export { Root };
