import styled from "styled-components";

import vars from "variables";

const Root = styled.li`
  padding: 10px 17px;

  strong {
    display: block;
    font-size: 0.65rem;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 2rem;
    opacity: 0.7;
  }

  .arrow {
    width: 0;
    height: 0;
    margin-top: 13px;
    font-size: 0;
    float: left;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid ${vars.colors.chatPurple};
  }

  .raw-html {
    margin-left: 5px;
    padding: 6px 13px;
    clear: right;
    font-size: 0.85rem;
    border-radius: 4px;
    background: ${vars.colors.chatPurple};
  }

  .raw-html p {
    color: white;
  }

  .options {
    text-align: right;
  }

  .options div {
    margin-left: 10px;
    display: inline-block;
  }

  .options div span {
    margin-top: 10px;
    padding: 6px 13px;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.7rem;
    text-transform: uppercase;
    background: ${vars.colors.blue};
    border-radius: 4px;
  }

  .options div.START {
    display: block;
  }

  .options div.START span {
    color: white;
    background: ${vars.colors.chatPurple};
  }

  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    /* styling */
  }
`;

export { Root };
