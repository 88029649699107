import React from 'react';
import { Root } from './style';

import Menu from './Menu';

const Footer = () => {
  return (
    <Root>
      <Menu/>
    </Root>
  );
};

export default Footer;
