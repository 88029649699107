import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  ul {
    margin: 20px 0 15px 20px;
  }

  li {
    display: list-item;
    list-style: disc;
  }

  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    /* styling */
  }
`;

export { Root };
