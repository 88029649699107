const React = require("react");

const { DashboardProvider } = require("./src/dashboard");
const { Web3Provider } = require("./src/web3");

const { localStorageGet, localStorageSet } = require("./src/utils");

exports.onRouteUpdate = ({ location }) => {
  document.body.setAttribute("path", location.pathname);
  document.body.classList.remove("noscroll");

  const header = document.getElementsByClassName("header")[0];
  header && header.removeAttribute("data-section");

  const checkbox = document.querySelector("#menu input[type=checkbox]");
  if (checkbox) {
    checkbox.checked = false;
  }

  const history = localStorageGet("history") || [];
  history.push(`${Math.floor(Date.now() / 1000)} ${location.pathname}`);
  localStorageSet("history", history.slice(-25));
};

exports.wrapRootElement = ({ element }) => {
  return (
    <DashboardProvider>
      <Web3Provider>{element}</Web3Provider>
    </DashboardProvider>
  );
};

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    const scrollTo = () => {
      const el = document.getElementById(location.hash.replace("#", ""));
      if (el) {
        const bodyRect = document.body.getBoundingClientRect();
        let offset = el.getBoundingClientRect().top - bodyRect.top;
        window.scrollTo(0, offset - 180);
      } else {
        setTimeout(scrollTo, 50);
      }
    };
    setTimeout(scrollTo, 50);
  } else {
    window.scrollTo(0, 0);
  }
  return false;
};
